import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
import "../../src/prism-powershell-language";
import "../../src/styles/legend.css";
import { Navigation } from "../../src/components/Navigation";
import { SelectAllText } from "../../src/components/SelectAllText";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "create-the-app"
    }}>{`Create the App`}</h1>
    <p>{`Now, I know a lot of you probably dread the black and white window known as the terminal. 😰`}<br parentName="p"></br>{`
`}{`Fear not, in time it'll become your friend `}{`—`}{` you'll be using it more and more as you progress
this guide. 💪`}</p>
    <p>{`In the last step you opened `}<em parentName="p">{`Windows PowerShell`}</em>{` in administrative mode to install all the necessary tools.`}<br parentName="p"></br>{`
`}{`One of those tools is called `}<strong parentName="p">{`Terminal`}</strong>{`, which is what we'll be using from now on.
So go ahead, open your start menu and start the Terminal.`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup></p>
    <h2 {...{
      "id": "scaffold-the-extension"
    }}>{`Scaffold the extension`}</h2>
    <p>{`We have created a generator which does the heavy lifting of creating a new extension, for you,
so that you can focus on creating value, rather than debugging why your extension won't compile. 🪶`}</p>
    <p>{`Run the below code in the terminal:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-powershell select",
        "metastring": "className=select"
      }}>{`# We declare a variable here, so we don't have to write its value twice
$projectDirectory = "$env:USERPROFILE/source/repos/Skill Core AS/Vindfang Demo"

# Create the directory where your extension will live
mkdir $projectDirectory

# Navigate to the new directory
cd $projectDirectory

# Start the generator
yo skill-al-app

# That's it!
`}</code></pre>
    <p>{`You'll be presented with this guy 🎩:`}</p>
    <pre><code parentName="pre" {...{}}>{`.
     _-----_     ╭──────────────────╮
    |       |    │   Welcome to Skill Core  │
    |--(o)--|    │    AS's AL application   │
   \`---------´   │        generator!        │
    ( _´U\`_ )    ╰──────────────────╯
    /___A___\\   /
     |  ~  |
   __'.___.'__
 ´   \`  |° ´ Y \`
? What's the name of your app?
`}</code></pre>
    <p>{`Give the app a unique name, for instance your name followed by "Demo", i.e. "Ola Halvorsen Demo".`}<br parentName="p"></br>{`
`}{`For the rest of the options, simply hit enter without typing anything, and you'll get their defaults, which are good enough for this demo. ✅`}</p>
    <h2 {...{
      "id": "visual-studio-code"
    }}>{`Visual Studio Code`}</h2>
    <p>{`After hitting enter on the last question, Visual Studio Code will launch the workspace of your new extension (if you don't see it, then look behind your terminal window).`}</p>
    <figure>
  <img src="/public/images/vindfang-demo-app-json-error.png" title="The new extension workspace as shown in Visual Studio Code" />
  <figcaption>
    The <em>app.json</em> file is red, which indicates an error &mdash;{" "}
    <em>the package cache could not be found</em>.
  </figcaption>
    </figure>
    <p>{`There's a lot of files here, but one is initially red; the `}<em parentName="p">{`app.json`}</em>{` file. 🚫`}<br parentName="p"></br>{`
`}{`This is the regular error that you get every time you create a new extension.
It simply means that we have not downloaded the symbols yet (which are required to compile the extension).
We can get them from any running Business Central instance with the version
we're compiling for (in our case, that's just the latest release).`}</p>
    <p>{`To save you time, we have created yet another tool (start to see a pattern here? 🤭),
which fetches them from our Azure Artifacts package feed 🚀:`}</p>
    <figure>
  <img src="/public/images/vindfang-demo-download-symbols.png" title="Our custom download symbols command in Visual Studio Code" />
  <figcaption>
    <em>SKILL: Download symbols from Azure Artifacts</em> is the command we use
    to fetch the dependencies. The other command, <em>AL: Download symbols</em>,
    is the standard one that Microsoft provides.{" "}
    <b>
      NOTE: The command is context sensitive; it runs in the workspace folder of
      the currently opened file.
    </b>
  </figcaption>
    </figure>
    <p>{`Once you've logged in with your account, you should eventually see the `}<em parentName="p">{`.alpackages`}</em>{` folder appear with four app files. 👏`}</p>
    <p><em parentName="p">{`Note: This command actually runs once automatically when you open the workspace. So, in most cases, you don't have to do it manually.`}</em></p>
    <h2 {...{
      "id": "our-first-page-extension"
    }}>{`Our first page extension`}</h2>
    <p>{`To demonstrate that our app works, we're going to make a message pop up whenever we open the `}<em parentName="p">{`Customer List`}</em>{` in Business Central:`}</p>
    <legend className="code-title">
  app/src/pageextensions/VDCustomerListPIL.PageExt.al
    </legend>
    <pre><code parentName="pre" {...{
        "className": "language-al"
      }}>{`pageextension 60000 VD_CustomerList_PIL extends "Customer List"
{
    trigger OnOpenPage();
    begin
        Message('My first app says hello!');
    end;
}
`}</code></pre>
    <p>{`You can now try to compile your app, by hitting `}<inlineCode parentName="p">{`CTRL+SHIFT+B`}</inlineCode>{` on your keyboard:`}</p>
    <pre><code parentName="pre" {...{}}>{`Microsoft (R) AL Compiler version 9.4.10.7727
Copyright (C) Microsoft Corporation. All rights reserved

Compilation started for project 'Vindfang Demo' containing '1' files at '14:21:56.769'.


Compilation ended at '14:21:56.796'.

Success: The package is created.
`}</code></pre>
    <h2 {...{
      "id": "next-steps"
    }}>{`Next steps`}</h2>
    <p>{`Alright, this is going great! 🎉`}<br parentName="p"></br>{`
`}{`We're now ready to publish it to our own testing environment. ⚓`}</p>
    <hr></hr>
    <hr />
    <details>
  <summary id="fn-1">How to open the terminal...</summary>
  <figure>
    <img src="/public/images/terminal.png" title="How to open Terminal" />
    <figcaption>
      Open the start menu, search for Terminal and open it. Feel free to pin it
      to the taskbar, to make it easier to find later on.
    </figcaption>
  </figure>
    </details>
    <br />
    <Navigation previous="/my-first-extension/install-tools" next="/my-first-extension/create-sandbox" mdxType="Navigation" />
    <SelectAllText selector="pre.prism-code.select" mdxType="SelectAllText" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      